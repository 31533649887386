import React, { useEffect, useState } from "react";
import {
	getAllWorks,
	getIntroductionData,
	getAllClientData,
	getAllServiceData,
} from "../libs/sanity.ts";
import {
	IIntroduction,
	ISelectWork,
	IClientData,
	IServices,
} from "../libs/types.ts";
import { Introduction } from "../components/introServicesAndClients.tsx";
import { SelectWorks } from "../components/SelectWorks.tsx";
import { FixedLogo } from "../components/FixedLogo.tsx";
import { Footer } from "../components/Footer.tsx";
import retry from "async-retry";

export const Home = () => {
	const [works, setWorks] = useState<ISelectWork[]>([]);
	const [introData, setIntroData] = useState<IIntroduction>();
	const [clientData, setClientData] = useState<IClientData>();
	const [serviceData, setServiceData] = useState<IServices>();

	useEffect(() => {
		async function getWorks() {
			const res = await retry(
				async () => await getAllWorks({ published: true }),
				{
					retries: 3,
				}
			);
			if (res) {
				setWorks(res);
			}
		}

		async function getIntroData() {
			const res = await retry(getIntroductionData, { retries: 3 });
			if (res) {
				setIntroData(res);
			}
			return;
		}

		async function getClientData() {
			const res = await retry(getAllClientData, { retries: 3 });
			if (res) {
				setClientData(res);
			}
		}

		async function getServiceData() {
			const res = await retry(getAllServiceData);
			if (res) {
				setServiceData(res);
			}
		}

		if (!works.length) getWorks();

		if (!introData) getIntroData();

		if (!clientData) getClientData();

		if (!serviceData) getServiceData();
	}, []);

	return (
		<React.Fragment>
			{introData && clientData && serviceData ? (
				<Introduction
					introContent={introData}
					clientContent={clientData}
					serviceContent={serviceData}
				/>
			) : null}

			<SelectWorks works={works} />
			<FixedLogo />
			<Footer />
		</React.Fragment>
	);
};

export default Home;
